<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Guia General</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="form-group">
                <label for="">Fecha inicial</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_inicial"
                  :class="
                    $v.form.fecha_inicial.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="validaFechas"
                />
              </div>
              <div class="form-group">
                <label for="">Fecha final</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_final"
                  :class="
                    $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="validaFechas"
                />
              </div>
              <div class="form-group col-md-2">
                <label>N° GUIA</label>
                <input
                  type="number"
                  id="n_guia"
                  placeholder="N° GUIA"
                  v-model="filtros.n_guia"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="fecha_solicitud">TIPO PRODUCTO</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.producto_liquido_id"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="producto_liquido_id in listasForms.productos_liquidos"
                    :key="producto_liquido_id.id"
                    :value="producto_liquido_id.id"
                  >
                    {{ producto_liquido_id.nombre }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label>CEDULA CONDUCTOR</label>
                <input
                  type="number"
                  id="cedula_conductor"
                  placeholder="Cedula"
                  v-model="filtros.cedula_conductor"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>PLACA VEHICULO</label>
                <input
                  type="text"
                  id="placa"
                  placeholder="Placa"
                  v-model="filtros.placa"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Empresa Transportadora</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.transportadora_id"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="transportadora_id in listasForms.empresas"
                    :key="transportadora_id.id"
                    :value="transportadora_id.id"
                  >
                    {{ transportadora_id.razon_social }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>LUGAR DE ORIGEN</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="sitio_origen"
                  placeholder="Lugares de Origen"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.sitiosOrigenes"
                  :filterable="true"
                  @input="getSelectSitioOrigen()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>LUGAR DE DESTINO</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="sitio_destino"
                  placeholder="Lugares de Destino"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.sitiosDestinos"
                  :filterable="true"
                  @input="getSelectSitioDestino()"
                ></v-select>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.form.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.dinamicos.dinamicoGuiaGeneral'
                    )
                  "
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "DinamicoGuiaGeneral",
  components: {
    PivotTableComponent,
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      guia: [],
      sitio_origen: {},
      sitio_destino: {},

      form: {
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      filtros: {
        n_guia: null,
        producto_liquido_id: null,
        cedula_conductor: null,
        transportadora_id: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        placa: null,
      },
      listasForms: {
        productos_liquidos: [],
        sitiosOrigenes: [],
        sitiosDestinos: [],
        empresas: [],
      },
    };
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        fecha_menor = new Date(this.form.fecha_inicial).getTime();
        fecha_mayor = new Date(this.form.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 15) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 15 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_final) {
        var hoy = new Date();
        const fecha_final = new Date(this.form.fecha_final);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    back() {
      return this.$router.replace("/Hidrocarburos/Dinamicos");
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    getSelectSitioOrigen() {
      this.filtros.sitio_origen = {};
      this.filtros.sitio_origen_id = null;
      if (this.sitio_origen) {
        this.filtros.sitio_origen = this.sitio_origen;
        this.filtros.sitio_origen_id = this.sitio_origen.id;
      }
    },
    async getSitioOrigen() {
      await axios.get("api/admin/sitios/lista").then((response) => {
        this.listasForms.sitiosOrigenes = response.data;
      });
    },
    getSelectSitioDestino() {
      this.filtros.sitio_destino = {};
      this.filtros.sitio_destino_id = null;
      if (this.sitio_destino) {
        this.filtros.sitio_destino = this.sitio_destino;
        this.filtros.sitio_destino_id = this.sitio_destino.id;
      }
    },
    async getSitioDestino() {
      await axios.get("api/admin/sitios/lista").then((response) => {
        this.listasForms.sitiosDestinos = response.data;
      });
    },
    async getProductoLiquido() {
      await axios.get("api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productos_liquidos = response.data;
      });
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/guias/guiaGeneralPivotTable",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getSitioOrigen();
    await this.getSitioDestino();
    await this.getProductoLiquido();
    await this.getEmpresas();
    this.cargando = false;
  },
};
</script>
